@import "../node_modules/bootstrap/scss/bootstrap";

// Variables
$delete-bkg-color: #f1f1f1;
$delete-border-color: #bebebe;

// Global
#formFile {
  max-width: 20rem;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.disable-pointer {
  pointer-events: none;
}

.help-btn {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-size: 0.8rem;
  transform: translate(-10px, -10px);
  span {
    display: inline-block;
    transform: translate(0px, -2px);
  }
}

.section-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  h2 {
    margin-bottom: 0px;
  }
}

// Custom classes

/* Custom classes for fractional margins */
.m-x-0-5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

// App container
.app-container {
  height: 100vh;
  grid-template-rows: auto 1fr auto;
  .grid {
    display: grid;
  }
}

// Loading screen
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vw;
  font-size: 1rem;
}

// Converter
.converter {
  h3 {
    margin-bottom: 0px;
  }
  .swap-button {
    background-color: #fb8500;
    border-color: transparent;
    // color: white; /* Ensure text is white */
    // padding: 10px 20px; /* Adjust as needed */
    // font-size: 16px; /* Adjust as needed */
    // border-radius: 5px; /* Rounded corners */
    // transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover and active */
    // cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  }

  .swap-button:hover {
    background-color: #e37900; /* Slightly darker orange */
    // transform: scale(1.05); /* Slightly enlarge the button */
  }

  .swap-button:active {
    background-color: #dd7600; /* Even darker when pressed */
    border-color: transparent;
    // transform: scale(0.95); /* Slightly shrink the button */
  }

  .swap-button:focus {
    outline: none; /* Remove outline */
    border-color: transparent;
    // box-shadow: 0 0 0 3px rgba(251, 133, 0, 0.5); /* Add a subtle glow effect */
  }
}

// Landing page
.landing {
  max-width: 100vw;
  .react-player {
    padding-top: 0;
    max-width: max-content;
  }
  .section-1 {
    h2 {
      margin-bottom: 0px;
    }
  }
  .video-row {
    background-color: black;
  }
  .cta-row {
    background-color: white;
  }
  .pay-row {
    color: white;
    background-color: rgb(12, 32, 58);
  }
  .key-item {
    h5 {
      margin-bottom: 0px;
    }
    p {
      text-align: left;
    }
    .heading {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .icon {
      font-size: 2.5rem;
    }
    .title {
      font-weight: bold;
    }
  }

  .player-wrapper {
    position: relative;
    width: auto; // Reset width
    height: auto; // Reset height
    .try-free-btn {
      position: absolute;
      background-color: #fb8500;
      border-color: transparent;
      font-weight: bold;
      top: 82%;
      left: 15%;
      right: 15%;
      z-index: 1;
    }
    .react-player {
      position: relative;
      z-index: 0;
    }
  }

  .how-to-icon {
    display: flex;
    gap: 0.7rem;
    justify-content: center;
    svg {
      font-size: 2rem;
    }
  }

  .card {
    width: 18rem;
    height: 15rem;
    border-width: 10px;
    h5 {
      margin-bottom: 0px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .card#step-1 {
    border-color: #495867;
  }
  .card#step-2 {
    border-color: #577399;
  }
  .card#step-3 {
    border-color: #bdd5ea;
  }

  .key-points-list {
    .list-group-item {
      color: white;
      border-color: white;
      background: none;
    }
    div {
      p {
        text-align: center;
      }
    }
  }
}

// Footer
.footer {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: lightgray;
  .links {
    font-size: small;
  }
  .copyright {
    font-size: x-small;
  }
}

// Add media
.add-media {
  .add-media-bottom-btns {
    button {
      width: fit-content;
    }
  }
}

// Add video
.add-video-center-component {
  max-height: 600px;
  max-width: 600px;
  p {
    text-align: center;
  }
  video {
    max-height: 300px;
    max-width: 600px;
  }
  button {
    width: fit-content;
  }
}

// EasyCrop styles
.crop-container {
  position: relative;
  height: 266px !important;
  width: 266px !important;
}

// MediaScroll styles
.media-scroller {
  h5 {
    margin-bottom: 0px;
  }

  // img {
  //   max-height: 300px;
  // }

  .add-media {
    color: #6c757e;
  }
  .add-media.disabled {
    color: #b8c2cc;
  }

  .react-horizontal-scrolling-menu--wrapper {
    border-top: 1px solid rgba(229, 229, 229, 0.98);
    // padding-top: 0.3rem;
    // margin-top: 0.3rem;
    border-bottom: 1px solid rgba(229, 229, 229, 0.98);
    // padding-bottom: 0.3rem;
    // margin-bottom: 0.3rem;
  }
}

.scroll-item {
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border: 0.2rem solid lightgrey;
  img.face {
    border: 0.2rem solid lightgrey !important;
  }
}

.scroll-item-selected {
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border: 0.2rem solid #fb8500;
  img.face {
    border: 0.2rem solid #fb8500 !important;
  }
}
.scroll-item-image {
  border: none !important;
  .timer {
    width: 37px !important;
    object-fit: cover;
    z-index: 1;
  }
  img {
    width: 60px !important;
    object-fit: cover;
    border-radius: 50%;
  }
}
.scroll-item-video {
  img {
    max-width: 240px;
  }
}
.scroll-item-video-not-ok {
  width: 240px;
}
.video-thumbnail-container {
  position: relative;
  .scroll-item-overlay {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
  }
}

.slider-outer-div {
  position: relative;
  p {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    z-index: 100;
  }
}

// React player
.player-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
}

// Gallery
.list-group-item {
  background-color: transparent;
  border: none;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.transparent {
  img.face {
    opacity: 0.5 !important;
  }
  img.video-thumb {
    opacity: 0.6 !important;
  }
  .react-player__preview {
    opacity: 0.3;
  }
  video {
    opacity: 0.3;
  }
}

.gallery-pagination {
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.page-item a {
    color: #6c757d;
  }
  li.active a {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
}

.gallery-item-container {
  position: relative;
  text-align: center;
  width: 20vw;
  height: 11.25vw;
  min-width: 272px;
  min-height: 153px;
  align-items: center;
  display: flex;
  overflow: hidden;
  .timer {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    width: 37px !important;
    object-fit: cover;
    z-index: 1;
  }
  img.face {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    width: 60px !important;
    object-fit: cover;
    border-radius: 50%;
    border: 0.2rem solid lightgrey;
  }
  img.video-thumb {
    width: 100%;
  }
  .progress {
    position: absolute;
    bottom: 0.6rem;
    left: 0.6rem;
    width: calc(100% - 1.2rem);
    background-color: rgb(255 255 255 / 75%);
  }
  .dismiss-text {
    position: absolute;
    margin: auto;
    width: 100%;
    padding-right: 15%;
    padding-left: 15%;
    background-color: white;
    font-size: 0.8rem;
    opacity: 0.75;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  button {
    border-radius: 50%;
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
  }
}

.converter-video-item {
  @extend .gallery-item-container;
  background-color: #000000;
  min-width: 153px;
  width: 20 * 0.64vw; // .64 instead of .66 to avoid black vertical line artifacts
  height: 11.25 * 0.66vw;
  justify-content: center;
  img.video-thumb {
    width: auto;
    height: 100% !important;
  }
}

.gallery-item-container-edit {
  position: relative;
  width: 20 * 0.66vw;
  height: 11.25 * 0.66vw;
  min-width: 272 * 0.66px;
  min-height: 153 * 0.66px;
  align-items: center;
  display: flex;
  overflow: hidden;
  img.face {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    width: 40px !important;
    object-fit: cover;
    border-radius: 50%;
    border: 0.2rem solid lightgrey;
  }
  img.video-thumb {
    width: 100%;
  }
  .progress {
    position: absolute;
    bottom: 0.6rem;
    left: 0.6rem;
    width: calc(100% - 1.2rem);
    background-color: rgb(255 255 255 / 75%);
  }
}

.converter-video-item-edit {
  @extend .gallery-item-container-edit;
  background-color: #000000;
  min-width: 153 * 0.66px;
  width: 20 * 0.66 * 0.66vw;
  height: 11.25 * 0.66 * 0.66vw;
  justify-content: center;
  img.video-thumb {
    width: auto;
    height: 100% !important;
  }
}

// Edit
.edit-media-list-group {
  .list-group-item {
    .edit-media-container {
      height: 11.25 * 0.66vw;
      min-height: 153 * 0.66px;
      width: 20 * 0.66vw;
      min-width: floor(272 * 0.66px);
      text-align: center;
      background-color: black;
      img.face {
        width: 20vw !important;
        max-width: 99px !important;
        object-fit: cover;
        border-radius: 50%;
        border: 0.2rem solid lightgrey;
      }
      img.video-thumb {
        height: 100%;
        max-width: 100%;
      }
    }
  }
}

.edit-container {
  height: 11.25vw;
  img.face {
    width: 20vw !important;
    max-width: 99px !important;
    object-fit: cover;
    border-radius: 50%;
    border: 0.2rem solid lightgrey;
  }
  img.video-thumb {
    max-width: 160px !important;
    border: 0.2rem solid lightgrey;
  }
}

.delete-info {
  font-style: italic;
  font-size: 0.8rem;
}

.options-btns {
  button {
    margin: 0.2rem;
  }
}

// Pagination

.custom-pagination {
  ul {
    margin-bottom: 0rem;
    justify-content: start;
    --bs-pagination-active-bg: #6c757d;
    --bs-pagination-active-border-color: #6c757d;
    --bs-pagination-color: var(--bs-secondary-text-emphasis);
    --bs-pagination-hover-color: var(--bs-secondary-text-emphasis);
    // li {
    //   &,
    //   & * {
    //     padding-left: 0; // Remove left padding
    //     padding-right: 0; // Remove right padding
    //     text-align: center; // Center the text
    //   }
    //   width: 2rem;
    //   &:first-child {
    //     width: 3rem;
    //   }
    //   &:last-child {
    //     width: 3rem;
    //   }
    // }
  }
}

// Autodelete-related styles

.autodelete-text {
  font-size: 0.72rem;
  font-style: italic;
  color: grey;
  text-align: center;
}

.scroll-item-badge-container {
  position: relative;
}

.notification-button {
  position: relative;
  margin-right: 10px; /* Add spacing between badge and text */
}

.notification-badge {
  position: absolute;
  top: -10px; /* Adjust vertical positioning */
  right: -5px; /* Adjust horizontal positioning */
  color: #333333;
  background-color: $delete-bkg-color;
  border-color: $delete-border-color;
  border-radius: 50%;
  width: 15px; /* Set width and height to ensure a circular shape */
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.notification-banner-gallery {
  font-size: 0.75rem;
  display: inline-block;
  span {
    padding-top: 0.1rem;
  }
}

.scroll-item-will-delete {
  background-color: $delete-bkg-color !important;
}

// Shop stuff

.shop-wrap {
  display: flex;
  flex-direction: row;
  align-content: center;
  button {
    border: none;
    background: none;
    img {
      height: 31px;
      width: auto;
    }
  }
}

// Shop cards

.shop-card.pass {
  color: rgb(255, 255, 255);
  background: #134e5e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #71b280,
    #1e768f,
    #134e5e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #71b280,
    #1e768f,
    #134e5e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .bottom-divider {
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-bottom: 0.7rem;
    margin-bottom: 1rem;
  }
  .per-month {
    font-style: italic;
    font-size: 1rem;
  }
  .benefit-table {
    text-align: center;
    table {
      color: white !important;
      th {
        font-size: 0.8rem;
      }
    }
  }
  .benefits-container {
    size: 1rem;
  }
}
.shop-card.large {
  color: white;
  .card-body {
    color: white !important;
  }
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #4e7a8d,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #4e7a8d,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.shop-card.medium {
  color: white;
  .card-body {
    color: white !important;
  }
  background: #373b44; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #4286f4,
    #373b44
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #4286f4,
    #373b44
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.shop-card.small {
  background: #2980b9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #ffffff,
    #6dd5fa,
    #2980b9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #ffffff,
    #6dd5fa,
    #2980b9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.shop-card {
  button {
    background-color: #fb8500;
    border-color: transparent;
    font-weight: bold;
    width: 100%;
    height: 100%;
  }
  button:hover {
    background-color: #f18100;
    border-color: transparent;
    font-weight: bold;
    width: 100%;
    height: 100%;
  }

  .card {
    background-color: unset;
    border-color: transparent;
  }
  width: 272px;
  margin-bottom: 1.5rem;
  font-size: 1rem;

  background-size: 100% 100% !important;
  border-radius: 0.5rem;
  //animation: gradient 8s ease infinite;

  .duration {
    font-size: 1.2rem;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.shop-btns-container {
  display: flex;
  flex-direction: column;
  gap: 0.36rem;
}

// Time range
.react_time_range__time_range_container {
  width: 100% !important;
  .react_time_range__track {
    background-color: rgb(98 135 203 / 50%) !important;
    border-left: 1px solid #0a58ca !important;
    border-right: 1px solid #0a58ca !important;
  }
  .react_time_range__handle_marker {
    background-color: #084298 !important;
  }
  .react_time_range__rail__inner {
    background-color: #dddddd;
    border-bottom: 1px solid #babbbd;
  }
}

// Settings

.settings-list-group {
  i {
    color: dimgray;
  }
}
